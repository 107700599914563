import "@/registerServiceWorker";

import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import vuetify from "@/plugins/vuetify";
import { Axios } from "@/plugins/axios";
import { LayoutMessages } from "@/plugins/layout-messages";
import { registerComponents } from "@/components";
import "@/assets/scss/index.scss";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import { injectCSSVariables } from "@/plugins/colors";

Vue.config.productionTip = false;

Vue.use(Axios);
Vue.use(LayoutMessages);
registerComponents(Vue);

injectCSSVariables();

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
