import Color from "color";
import { SYSTEM_PALETTE } from "@/config/colors";

/**
 * Generate shades to provided color
 * @param {string} color
 * @returns {Record<string, string>}
 */
function generateShades(color) {
  const result = {
    base: color,
  };

  const ratio = 0.1;

  for (let i = 1; i <= 4; i++) {
    result[`lighten-${i}`] = Color(color)
      .lighten(ratio * i)
      .hex();
    result[`darken-${i}`] = Color(color)
      .darken(ratio * i)
      .hex();
  }

  return result;
}

/**
 * Returns palette
 * @param {Record<string, string>} palette
 * @returns {Record<string, Record<string, string>>}
 */
export function getThemeColors(palette) {
  const entries = Object.entries(palette);

  return entries.reduce((curr, prev) => {
    const [key, color] = prev;
    curr[key] = generateShades(color);
    return curr;
  }, {});
}

/**
 * Generates CSS variables as a string to be injected
 * @param palette
 * @returns {string}
 */
function generateCSSVariables(palette) {
  const shades = getThemeColors(palette);
  const entries = Object.entries(shades);
  const opacityLevels = [0.1, 0.25, 0.5, 0.75];

  let cssVariables = ":root {";
  for (const [key, value] of entries) {
    const nested = Object.entries(value);

    for (const [shade, value] of nested) {
      if (shade === "base") {
        cssVariables += `--${key}: ${value};`;
      } else {
        cssVariables += `--${key}-${shade}: ${value};`;
      }

      for (const opacity of opacityLevels) {
        if (shade === "base") {
          cssVariables += `--${key}-${opacity * 100}: ${Color(value)
            .alpha(opacity)
            .rgb()
            .toString()};`;
        } else {
          cssVariables += `--${key}-${shade}-${opacity * 100}: ${Color(value)
            .alpha(opacity)
            .rgb()
            .toString()};`;
        }
      }
    }
  }
  cssVariables += "}";

  return cssVariables;
}

/**
 * Returns color palette defined in
 * @returns {Record<string, Record<string, string>>}
 */
export function getPalette() {
  if (typeof global["SYSTEM_PALETTE"] === "undefined") {
    global["SYSTEM_PALETTE"] = getThemeColors(SYSTEM_PALETTE);
  }

  return global["SYSTEM_PALETTE"];
}

/**
 * Inject CSS Variables to app scope
 */
export function injectCSSVariables() {
  const variables = generateCSSVariables(SYSTEM_PALETTE);
  const style = document.createElement("style");

  style.innerHTML = variables;
  document.head.appendChild(style);
}
