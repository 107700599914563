import { cloneDeep } from "lodash";

/**
 * Create initials based on name
 * @param {string} name
 * @returns {string}
 */
export function createInitialsBasedOnName(name) {
  if (
    typeof name !== "string" ||
    (typeof name === "string" && name.length < 2)
  ) {
    return "N/A";
  }

  const splittedName = name
    .split(" ")
    .filter((entry) => typeof entry === "string" && entry.length > 0);

  if (splittedName.length < 2) {
    return name.substring(0, 2).toUpperCase();
  }

  return (splittedName[0][0] + splittedName[1][0]).toUpperCase();
}

/**
 * Create initials based on provided first and last name (mainly for users)
 * @param {string} firstName
 * @param {string} lastName
 * @returns {string}
 */
export function createInitialsBasedOnNames(firstName, lastName) {
  if (
    typeof firstName !== "string" ||
    (typeof firstName === "string" && firstName.length === 0) ||
    typeof lastName !== "string" ||
    (typeof lastName === "string" && lastName.length === 0)
  ) {
    return "N/A";
  }

  return (firstName[0] + lastName[0]).toUpperCase();
}

/**
 * Parse any string to PascalCase
 * @param {string} inputString
 * @returns {string}
 */
export function toPascalCase(inputString) {
  // Split the input string by spaces or underscores
  const words = inputString.split(/[\s_]+/);

  // Capitalize the first character of each word
  const pascalCaseWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });

  // Join the words together to form the PascalCase string
  const pascalCaseString = pascalCaseWords.join("");

  return pascalCaseString;
}

/**
 * Extract customer stripe ID from organization if possible
 * @param {object} organization
 * @returns {string}
 */
export function getStripeCustomerIdFromOrganization(organization) {
  if (
    organization === null ||
    typeof organization === "undefined" ||
    typeof organization === "string"
  ) {
    return "N/A";
  }

  if (
    !organization.stripeCustomerId ||
    organization.stripeCustomerId.length === 0
  ) {
    return "N/A";
  }

  return organization.stripeCustomerId;
}

/**
 * Create visual limitation of text (if needed)
 * @param {string} text
 * @param {number} limit default to 64
 * @returns {string}
 */
export function limitTextLength(text, limit = 64) {
  if (typeof text !== "string") {
    return "";
  }

  const dots = "…";

  if (text.length > limit) {
    return text.substring(0, limit - dots.length).trim() + dots;
  }

  return text;
}

/**
 * Capitalize first letter of string
 * @param {string} text
 * @returns {string}
 */
export function capitalizeFirstLetter(text) {
  if (typeof text !== "string" || text.length === 0) return "";

  return text[0].toUpperCase() + text.substring(1);
}

/**
 * Lowercase first letter of string
 * @param {string} text
 * @returns {string}
 */
export function lowercaseFirstLetter(text) {
  if (typeof text !== "string" || text.length === 0) return "";

  return text[0].toLowerCase() + text.substring(1);
}

/**
 * Sanitize html
 * @param {string} html
 * @returns {string}
 */
export function sanitizeHTML(html) {
  const parser = new DOMParser();
  const parsedHtml = parser.parseFromString(html, "text/html");
  const scripts = Array.from(parsedHtml.getElementsByTagName("script"));

  scripts.forEach((node) => {
    if (node.parentNode) {
      node.parentNode.removeChild(node);
    }
  });

  return parsedHtml.documentElement.outerHTML.replaceAll("\\n", "<br />");
}

/**
 * Sanitize provided text
 * @param {string} text
 * @returns {string}
 */
export function sanitizeText(text) {
  return sanitizeHTML(text);
  // return makeTextInteractive(sanitizedText);
}

/**
 * Check if provided string is safe for URL usage
 * @param {string} str
 * @returns {boolean}
 */
export function isUrlSafe(str) {
  const urlSafeRegex = /^[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;=]*$/;
  return urlSafeRegex.test(str);
}

/**
 * Trim outer hyphens
 * @param str
 * @returns {string}
 */
export function trimOuterHyphens(str) {
  return str.replace(/^-+|-+$/g, "");
}
