<template>
  <div class="d-flex justify-center align-center rounded white">
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          text
          color="primary"
          tile
          v-bind="attrs"
          v-on="on"
          exact
          href="https://go.oncehub.com/LinkedClientOnboarding"
          target="_blank"
        >
          Book onboarding
        </v-btn>
      </template>
      Having an issue with the setup wizard? Book a free onboarding with our
      customer success team
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          color="primary"
          tile
          v-bind="attrs"
          v-on="on"
          exact
          href="mailto:support@linkedclient.com"
        >
          <v-icon>mdi-email</v-icon>
        </v-btn>
      </template>
      Contact our support team
    </v-tooltip>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          color="primary"
          tile
          v-bind="attrs"
          v-on="on"
          @click="logout"
        >
          <v-icon>mdi-logout</v-icon>
        </v-btn>
      </template>
      Logout
    </v-tooltip>
  </div>
</template>
<script>
export default {
  methods: {
    async logout() {
      await this.$store.dispatch("auth/clearDoppelganger");
      await this.$store.dispatch("auth/logout");
    },
  },
};
</script>
