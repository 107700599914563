<script>
export default {
  name: "PublicLayout",
};
</script>

<template>
  <v-app>
    <v-main>
      <v-row>
        <v-col cols="12" class="d-flex flex-column align-center py-8">
          <a target="_blank" href="https://linkedclient.com/" class="mt-8">
            <img
              style="width: 300px"
              class="d-block"
              src="@/assets/img/lc-logo-full.png"
              alt="LinkedClient Logo"
            />
          </a>

          <span class="text-body-2 mt-6">
            Scheduling powered by
            <a target="_blank" href="https://linkedclient.com/">Elsie AI</a>, a
            LinkedClient B2B Sales AI assistant.
          </span>
        </v-col>
      </v-row>

      <slot />
    </v-main>
  </v-app>
</template>
