import RelativeDateVue from "./components/Global/RelativeDate.vue";
import MultipleConditionSelectorVue from "./components/Global/MultipleConditionSelector/MultipleConditionSelector.vue";

const COMPONENTS = {
  RelativeDate: RelativeDateVue,
  MultipleConditionSelector: MultipleConditionSelectorVue,
};

/**
 * Register Components in global scope
 * @param {Vue | VueConstructor} vue
 */
export function registerComponents(vue) {
  /**
   * @type {Record<string, any>}
   */
  const entries = Object.entries(COMPONENTS);

  for (const [key, component] of entries) {
    vue.component(key, component);
  }
}
