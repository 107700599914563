<template>
  <div class="d-flex align-center">
    <v-progress-circular
      :rotate="-90"
      :size="progressSize"
      :width="progressWidth"
      :value="value"
      color="primary"
    >
      <span class="text--secondary text-small">{{ textInside }}</span>
    </v-progress-circular>
    <span class="ml-4">{{ label }}</span>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0,
    },
    progressSize: {
      type: Number,
      default: 55,
    },
    progressWidth: {
      type: Number,
      default: 6,
    },
    textInside: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
  },
};
</script>
