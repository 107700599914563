import store from "../store";
import {DateTime} from "luxon";

/**
 * Split array into chunks
 * @param {any[]} arr
 * @param {number} size
 * @returns {any[]}
 */
export function chunk(arr, size) {
  const chunkedArr = [];
  for (let i = 0; i < arr.length; i += size) {
    chunkedArr.push(arr.slice(i, i + size));
  }
  return chunkedArr;
}

/**
 * Create escaped regex
 * @param {string} text
 * @param {string} flags
 * @returns {RegExp}
 */
export function createEscapedRegex(text, flags) {
  return new RegExp(text.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"), flags);
}

/**
 * Check if provided value is number
 * @param {any} value
 * @returns {boolean}
 */
export function isNumber(value) {
  return typeof value === "number";
}

/**
 * Calculate percentage
 * @param {number} partial
 * @param {number} total
 * @returns {number}
 */
export function percentage(partial, total) {
  if (isNaN(partial) || isNaN(total)) {
    return 0;
  }
  if (partial === 0 && total === 0) return 0;
  return (partial / total) * 100;
}

/**
 * Copy value to clipboard
 * @param {string} text
 * @returns {void}
 */
export function copyToClipboard(text) {
  if (typeof text !== "string" || text.length === 0) return;

  navigator.clipboard.writeText(text);

  store.dispatch("layoutMessages/addMessage", {
    type: "success",
    msg: "Text was copied to clipboard",
  });
}

/**
 * Get date in more readable format
 * @param {string | number | Date} date
 * @returns {string}
 */
export function getReadableDate(date) {
  const dt = getDateTimeClassBasedOnInput(date);
  const format = "dd-LL-yyyy";
  const timeFormat = "HH:mm:ss";

  if (typeof dt === "undefined") {
    return "Invalid date";
  }

  return `${dt.toFormat(format)} at ${dt.toFormat(timeFormat)}`;
}

/**
 * Returns calendar format
 * @param {DateTime} dt
 * @returns
 */
function getCalendarFormat(dt) {
  const now = DateTime.now();
  const diff = dt.diff(now.startOf("day"), "days").as("days");
  return diff < -6
    ? "sameElse"
    : diff < -1
    ? "lastWeek"
    : diff < 0
    ? "lastDay"
    : diff < 1
    ? "sameDay"
    : diff < 2
    ? "nextDay"
    : diff < 7
    ? "nextWeek"
    : "sameElse";
}

/**
 * Check if date string is in specific format
 * @param dateString
 * @returns {boolean}
 */
function isDateInSpecificFormat(dateString) {
  const dateFormatRegex =
    /^(Mon|Tue|Wed|Thu|Fri|Sat|Sun), (Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec) \d{2}, \d{4}, \d{2}:\d{2} (AM|PM)$/;
  return dateFormatRegex.test(dateString);
}

/**
 * Returns date time class based on provided input. It will return undefined if provided data is invalid.
 * @param {string | number | Date} date
 */
function getDateTimeClassBasedOnInput(date) {
  if (date instanceof Date) {
    return DateTime.fromJSDate(date);
  }

  if (typeof date === "number") {
    return DateTime.fromMillis(date);
  }

  if (isDateInSpecificFormat(date)) {
    const formatString = "EEE, MMM dd, yyyy, hh:mm a";
    return DateTime.fromFormat(date, formatString);
  }

  if (typeof date === "string") {
    DateTime.fromSQL(date);
    return DateTime.fromISO(date);
  }

  return undefined;
}

/**
 * Parses dates to relative form (just like a `calendar` function from moment library but with use of `Luxon`)
 * @param {string | number | Date} date
 * @param {boolean} includeTime
 * @returns {string}
 */
export function toRelativeCalendar(date, includeTime = false) {
  const dt = getDateTimeClassBasedOnInput(date);

  if (typeof dt === "undefined") {
    return "Invalid date";
  }

  const formats = {
    sameDay: "'Today'",
    nextDay: "'Tomorrow'",
    nextWeek: "EEEE",
    lastDay: "'Yesterday'",
    lastWeek: "'Last' EEEE",
    sameElse: "dd-MM-yyyy",
  };

  const format = getCalendarFormat(dt);

  if (includeTime === true) {
    const time = dt.toFormat("HH:mm");

    return `${dt.toFormat(formats[format])} at ${time}`;
  }

  return dt.toFormat(formats[format]);
}

/**
 * Get date in more readable format
 * @param {string | number | Date} date
 * @returns {string}
 */
export function getShortReadableDate(date) {
  return getDateTimeClassBasedOnInput(date).toFormat("dd-LL-yyyy");
}

/**
 * Download file as csv file
 * @param {string} payload
 * @param {string} filenameAppendix
 * @returns {void}
 */
export function downloadAsCSV(payload, filenameAppendix) {
  const blob = new Blob([payload], { type: "text/csv;charset=utf-8;" });
  const filename = `${DateTime.now().toFormat(
    "dd-LL-yyyy"
  )}-${filenameAppendix}.csv`;

  // If somehow someone is using internet explorer we can handle it
  if (navigator.msSaveBlob) {
    return navigator.msSaveBlob(blob, filename);
  }

  const anchor = document.createElement("a");

  // Detection if current browser support download attribute in anchor tag
  if (typeof anchor.download === "undefined") {
    return store.dispatch("layoutMessages/addMessage", {
      type: "warning",
      msg: "It seems that you are using browser which not support file downloading. Please use different browser for this export",
    });
  }

  const url = URL.createObjectURL(blob);
  anchor.style.visibility = "hidden";
  anchor.setAttribute("href", url);
  anchor.setAttribute("download", filename);
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
}

/**
 * Download some content as file
 */
export function downloadAsFile(content, appendix, extension) {
  let type = "text/csv;charset=utf-8;";

  if (extension === "json") {
    type = "application/json;utf-8";
  }

  const blob = new Blob([content], { type });
  const filename = `${DateTime.now().toFormat(
    "dd-LL-yyyy-HH-mm-ss"
  )}-${appendix}.${extension}`;

  // If somehow someone is using internet explorer we can handle it
  if (navigator.msSaveBlob) {
    return navigator.msSaveBlob(blob, filename);
  }

  const anchor = document.createElement("a");

  // Detection if current browser support download attribute in anchor tag
  if (typeof anchor.download === "undefined") {
    return store.dispatch("layoutMessages/addMessage", {
      type: "warning",
      msg: "It seems that you are using browser which not support file downloading. Please use different browser for this export",
    });
  }

  const url = URL.createObjectURL(blob);
  anchor.style.visibility = "hidden";
  anchor.setAttribute("href", url);
  anchor.setAttribute("download", filename);
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
}

/**
 * Round numbers correctly
 * @param {number} number
 * @returns {number}
 */
export function correctRoundingNumbers(number) {
  const decimalPart = number - Math.floor(number);
  if (decimalPart >= 0.5) {
    return Math.ceil(number);
  }
  return Math.floor(number);
}

/**
 * Stringify number
 * @param {number} number
 * @returns {string}
 */
export function stringifyNumber(number) {
  const special = [
    "zeroth",
    "first",
    "second",
    "third",
    "fourth",
    "fifth",
    "sixth",
    "seventh",
    "eighth",
    "ninth",
    "tenth",
    "eleventh",
    "twelvth",
    "thirteenth",
    "fourteenth",
    "fifteenth",
    "sixteenth",
    "seventeenth",
    "eighteenth",
    "nineteenth",
  ];
  const deca = [
    "twent",
    "thirt",
    "fourt",
    "fift",
    "sixt",
    "sevent",
    "eight",
    "ninet",
  ];

  if (number < 20) return special[number];
  if (number % 10 === 0) return deca[Math.floor(number / 10) - 2] + "ieth";
  return deca[Math.floor(number / 10) - 2] + "y-" + special[number % 10];
}

/**
 * Assign values to target object by providing pattern object. This is update over Object.assign because this function overwrites nested objects.
 * @param {object} target target object
 * @param {object} pattern object from which we can copy values to target object
 * @example
 *  const target = {a: 1, b: 2, c: {a: 1, b:2}};
 *  const pattern = {a: 10, c: {b: 10}};
 *
 *  Object.assign(target, pattern);
 *  // expected output: {a: 10, b:2, c: {b: 10}}
 *
 *  assignObjectRecursively(target, pattern);
 *  // expected output: {a: 10, b:2, c: {a: 10, b: 10}}
 *
 *
 * @returns {object} Updated target object
 */
export function assignObjectRecursively(target, pattern) {
  const result = Object.create({});
  const keys = Object.keys(target);

  for (const key of keys) {
    if (typeof target[key] === "object" && pattern[key]) {
      result[key] = assignObjectRecursively(target[key], pattern[key]);
    } else {
      result[key] = target[key];
    }
  }

  return result;
}
